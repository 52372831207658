/* FAQ Grid Layout */
.faq-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 50px; /* Increased space between cards */
    padding: 10px; /* Increased padding around the grid */
    max-width: 1500px;  /* Size of the Box */
    margin: 0 auto;
    margin-bottom: 100px;
    margin-top: 40px;
  }
  

  .faq-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: all 0.3s ease;
  }
  
  /* FAQ Card Styles */
  .faq-card {
    background-color: #535559;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    padding: 20px; /* Increased padding inside the card */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    transition: all 0.3s ease;
    box-shadow: 5px 10px #181818;
    
  }
  
  .faq-card.expanded {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: scale(1.05); /* Slightly scale up the expanded card */
  }
  
  .faq-card-image {
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px; /* Increased space between image and title */
  }
  
  .faq-card-header {
    text-align: center;
  }
  
  .faq-card-title {
    font-size: 1.4rem; /* Increased font size for title */
    font-weight: bold;
    color:#e69a8d;
    margin-bottom: 15px;
  }
  
  .faq-card-content {
    padding: 20px; /* Increased padding for content */
    margin-top: 15px;
    font-size: 1.25rem;
    color: #fff;
    line-height: 1.6;
    border-top: 1px solid #ddd;
    background-color: #535559 ;
    border-radius: 8px;
    display: block;
  }
  
  /* Expanded Card Content Styles */
  .faq-card-content.expanded {
    background-color: #e8f4f8; /* Light blue background for expanded content */
  }
  

  .faq-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design */
  @media (max-width: 650px) {
    .faq-grid-container {
      grid-template-columns: 1fr; /* Switch to 1 column layout on smaller screens */
      padding: 20px; /* Adjust padding for smaller screens */
    }
  
    .faq-card-title {
      font-size: 1.2rem; /* Slightly smaller title on mobile */
    }
  
    .faq-card-content {
      padding: 15px; /* Reduce padding on smaller screens */
    }
}
@media (min-width:651px) and (max-width: 1200px) {
    .faq-grid-container {
        grid-template-columns: repeat(2, 1fr); /* 2-column layout */
    padding: 20px; /* Adjust padding for smaller screens */
    }

    .faq-card-title {
    font-size: 1.2rem; /* Slightly smaller title on mobile */
    }

    .faq-card-content {
    padding: 15px; /* Reduce padding on smaller screens */
    }
}
  