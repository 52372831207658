/* General team container */
.team {
  margin: 20px;
}

/* Styling for project-pi section */
#project-pi {
  margin-top: 8rem;
}

/* Styling for each past team member box */
#past-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px; /* Add margin for spacing between boxes */
  min-width: 13%; /* Set minimum width */
  min-height: 50px; /* Set minimum height */
  padding: 10px; /* Add padding for visual appeal */
  background-color: #535559; /* Updated background color */
  border-radius: 10px;
}

#past-box h3 {
  text-align: center;
  margin: 0;
  line-height: 1.2;
  color: #e69a8dff; /* Updated text color */
}

/* Responsive design */
@media only screen and (max-width: 768px) {
  #past-box {
    min-width: 80px; /* Adjust minimum width for smaller devices */
    min-height: 40px; /* Adjust minimum height for smaller devices */
  }
}

@media only screen and (max-width: 480px) {
  #past-box {
    min-width: 40px; /* Adjust minimum width for mobile devices */
    min-height: 30px; /* Adjust minimum height for mobile devices */
  }
}

/* Styling for the content of the team members */
.team-content {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center;
  gap: 4rem;
  text-align: center;
  margin-top: 2rem;
}

/* Styling for project PI description section */
.project-pi-description {
  flex-direction: column;
  padding: 10px;
  line-height: 150%;
  font-weight: 500;
  text-align: left;
  margin: 20px 10px 20px 20px;
}

.project-pi-details {
  text-align: justify;
  margin-top: 10px;
}

.project-pi-name {
  color: #e69a8dff; /* Updated text color */
  font-weight: bold;
  margin-bottom: 20px;
}

/* Styling for individual project boxes */
#box {
  padding: 10px;
  background: #535559; /* Updated background color */
  border-radius: 15px;
  transition: all 0.38s ease;
  flex: 1;
  max-width: 300px;
  height: 100%;
  box-shadow: 5px 10px #282626;
}

#project-pi-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background-color: #535559;
  box-shadow: 5px 10px #282626;
  border-radius: 15px;
}

/* Project box content */
.project-pi-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  gap: 20px;
}

/* Profile picture styling */

.profile-pic{
  width: 200px;
  height: 200px;
  margin: 15px;
  border-radius: 50%;
}

.box h3 {
  font-size: 20px;
  font-weight: 600;
  color: #e69a8dff; /* Updated text color */
  line-height: 0.9;
  margin-bottom: 6px;
  text-align: center;
}

/* Styling for heading center */
.center {
  font-size: 30px;
  font-weight: 600;
  color: #e69a8dff; /* Updated text color */
  margin: 35px 0px 2px 0px;
}

/* Icon styling */
.icons i {
  display: inline-block;
  color: #fff;
  font-size: 20px;
  margin: 0 8px;
  transition: all 0.38s ease;
}

.icons i:hover {
  transform: scale(1.2);
}

.box:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

/* Responsive Design using Tailwind's breakpoints */
.team-content {
  @apply flex flex-wrap justify-center gap-4 mt-8 text-center;
}

.project-pi-box {
  @apply flex justify-center bg-gray-600 shadow-xl rounded-lg;
}

.project-pi-content {
  @apply flex items-center justify-center gap-5 my-6;
}

.box {
  @apply p-4 bg-gray-600 rounded-lg transition-all ease-in-out duration-300;
  @apply flex-1 max-w-xs h-full shadow-md;
}

.box img {
  @apply w-48 h-48 mx-4;
}

.box h3 {
  @apply text-lg font-semibold text-[#e69a8dff] leading-tight mb-2 text-center;
}

/* Hover effect */
.box:hover {
  @apply transform translate-y-[-10px] cursor-pointer;
}

/* Media Queries for different screen sizes (using Tailwind’s responsive classes) */

/* Mobile */
@media (max-width: 768px) {
  .box {
    @apply max-w-xs;
  }

  .project-pi-content {
    @apply flex-col items-center;
  }

  .project-pi-description {
    @apply text-center;
  }

  .icons {
    @apply text-2xl;
  }

  .project-pi-name {
    @apply text-xl;
  }

  .project-pi-details {
    @apply text-base;
  }
}

/* Mobile Small */
@media (max-width: 375px) {
  .box {
    @apply max-w-xs;
  }

  .project-pi-content {
    @apply flex-col items-center;
  }

  .project-pi-description {
    @apply text-center;
  }

  .icons {
    @apply text-xl;
  }

  .project-pi-name {
    @apply text-lg;
  }

  .project-pi-details {
    @apply text-sm;
  }
}
