/* Reset margins and paddings */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-section {
    position: relative;
    width: 100%;
    height: 585px; /* Adjust height as needed */
    overflow: hidden;
   
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures video covers the entire container */
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #323232;

    
  }
  
  .video-overlay h1 {
    margin: 0;
    padding: 10px;
    text-align: center;
  }
  

/* Adjust the text overlay */
.overlay-text {
  position: absolute;
  top: 50%; /* Adjusted for better centering */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 3cap;
  width: 60%;
  font-size: 1.3rem;
}

/* Adjust the button container */
.overlay-button-container {
  position: absolute;
  top: 70%; /* Adjusted position for better visibility */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}

/* Add spacing between buttons */
.overlay-button-container button {
  margin: 8px 6px; /* Increased margin for better spacing */
  padding: 10px 15px;
  font-size: 1.2rem;
  margin-top: 20px;
  padding-inline: 20px;
}

@media  (max-width:414px) {

  .video-section{
    width:150%;
  }
}

@media screen and (max-width: 1150px){
  .overlay-text {

    font-size: 1rem; /* Reduce font size */
  }

}
/* Responsive styles for small screens */
@media screen and (max-width: 487px) {
  .overlay-text {
    top: 40%; /* Move text slightly higher */
    width: 100%; /* Allow more space */
    font-size: 1rem; /* Reduce font size */
  }

  .overlay-button-container {
  
    top: 65%; /* Move buttons up for better placement */
    margin-top: 50px;

  }

  .overlay-button-container button {
    display: block; /* Stack buttons vertically */
    width: 60%; /* Make buttons full width */
    margin: 8px auto; /* Center align */
  }
}


.overlay-button {
  background-color: #ddd; /* Button background color */
  color: #333; /* Button text color */
  border: solid; 
  border-width: 0.15cap; 
  padding: 20px 30px; /* Adjust padding as needed */
  font-size: 120%; /* Adjust font size as needed */
  font-weight: 700;
  cursor: pointer;
  border-radius: 15px; /* Add rounded corners */
  transition: background-color 0.3s; /* Add a smooth transition effect on hover */
}

.overlay-button:hover {
  background-color: #444; /* Change background color on hover */
  color: #ddd;
  border: none;

}

